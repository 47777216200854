/**
 * @generated SignedSource<<c3a606fc0296c9afaf843a174b9c29c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientTargetingData_FeatureFlags_Query$variables = {
  after?: string | null;
  companyId: string;
  csFlags?: ReadonlyArray<string> | null;
  first?: number | null;
  searchTerm?: string | null;
};
export type ClientTargetingData_FeatureFlags_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PaginatedFeatureFlagsFragment_FeatureFlags_Fragment">;
};
export type ClientTargetingData_FeatureFlags_Query$rawResponse = {
  readonly featureFlags: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly description: string;
        readonly id: string;
        readonly name: string;
        readonly rolloutVariants: ReadonlyArray<{
          readonly percent: number;
          readonly variation: string;
        }>;
        readonly targetingData: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly contextKey: string;
              readonly contextValue: string;
              readonly description: string;
              readonly updated: SerializedDateTime;
            };
          }>;
        } | null;
        readonly updated: SerializedDateTime;
      };
    }>;
    readonly pageInfo: {
      readonly endCursor: string;
      readonly hasNextPage: boolean;
    };
    readonly totalCount: number;
  } | null;
};
export type ClientTargetingData_FeatureFlags_Query = {
  rawResponse: ClientTargetingData_FeatureFlags_Query$rawResponse;
  response: ClientTargetingData_FeatureFlags_Query$data;
  variables: ClientTargetingData_FeatureFlags_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "csFlags"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v5 = {
  "kind": "Variable",
  "name": "companyId",
  "variableName": "companyId"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientTargetingData_FeatureFlags_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "PaginatedFeatureFlagsFragment_FeatureFlags_Fragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ClientTargetingData_FeatureFlags_Query",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "after",
            "variableName": "after"
          },
          (v5/*: any*/),
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
          },
          {
            "kind": "Variable",
            "name": "flagNames",
            "variableName": "csFlags"
          },
          {
            "kind": "Variable",
            "name": "search",
            "variableName": "searchTerm"
          }
        ],
        "concreteType": "FeatureFlagConnection",
        "kind": "LinkedField",
        "name": "featureFlags",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlagEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FeatureFlag",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Rollout",
                    "kind": "LinkedField",
                    "name": "rolloutVariants",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "percent",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "variation",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      (v5/*: any*/)
                    ],
                    "concreteType": "TargetingConnection",
                    "kind": "LinkedField",
                    "name": "targetingData",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TargetingDatumEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TargetingDatum",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "contextKey",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "contextValue",
                                "storageKey": null
                              },
                              (v7/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5a62280971e3424fad128aeba4e09aaf",
    "id": null,
    "metadata": {},
    "name": "ClientTargetingData_FeatureFlags_Query",
    "operationKind": "query",
    "text": "query ClientTargetingData_FeatureFlags_Query(\n  $companyId: ID!\n  $first: Int\n  $after: String\n  $searchTerm: String\n  $csFlags: [String!]\n) {\n  ...PaginatedFeatureFlagsFragment_FeatureFlags_Fragment\n}\n\nfragment ClientTargetingDataRow_FeatureFlag_FeatureFlags_Fragment on FeatureFlag {\n  id\n  name\n  updated\n  description\n  rolloutVariants {\n    percent\n    variation\n  }\n  targetingData(companyId: $companyId) {\n    edges {\n      node {\n        contextKey\n        contextValue\n        description\n        updated\n      }\n    }\n  }\n}\n\nfragment ClientTargetingFlagList_FeatureFlags_Fragment on FeatureFlagConnection {\n  edges {\n    node {\n      id\n      ...ClientTargetingDataRow_FeatureFlag_FeatureFlags_Fragment\n    }\n  }\n}\n\nfragment PaginatedFeatureFlagsFragment_FeatureFlags_Fragment on Query {\n  featureFlags(companyId: $companyId, search: $searchTerm, flagNames: $csFlags, first: $first, after: $after) {\n    totalCount\n    pageInfo {\n      ...usePaginatedDataFragment_pageInfo\n    }\n    ...ClientTargetingFlagList_FeatureFlags_Fragment\n  }\n}\n\nfragment usePaginatedDataFragment_pageInfo on PageInfo {\n  endCursor\n  hasNextPage\n}\n"
  }
};
})();

(node as any).hash = "a374db0ebc0f0363bef3cdb17ed38490";

export default node;
